import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { ENDPOINTS } from "../config"
import { httpFetch } from "../helpers"
import { NewsItemCP } from "../models/cockpitmodels"
import {
  NewsItem,
  ProjectItem,
  Settings,
  DirectorItem,
  GalleryItem,
} from "../models/models"

export interface ColorsContextData {
  ctxNews: NewsItem[]
  ctxHomeProjects: ProjectItem[]
  ctxProjects: ProjectItem[]
  ctxDirectors: DirectorItem[]
  ctxSettings: Settings
  isLoading: boolean
  fetchNews: () => void
  fetchHomeProjects: () => void
  fetchProjects: () => void
  fetchDirectors: () => void
  fetchSettings: () => void
}

export const colorsContextDefault: ColorsContextData = {
  ctxNews: [] as NewsItem[],
  ctxHomeProjects: [] as ProjectItem[],
  ctxProjects: [] as ProjectItem[],
  ctxDirectors: [] as DirectorItem[],
  ctxSettings: {} as Settings,
  isLoading: false,
  fetchNews: () => null,
  fetchHomeProjects: () => null,
  fetchProjects: () => null,
  fetchDirectors: () => null,
  fetchSettings: () => null,
}

export const ColorsContext = React.createContext<ColorsContextData>(
  colorsContextDefault
)

export function useColorsContext() {
  const colorsContext = useContext(ColorsContext)
  if (!colorsContext) {
    throw new Error(
      "useColorsContext must be used within the ColorsContext.Provider"
    )
  }
  return colorsContext
}

export function useColorsContextValue(): ColorsContextData {
  const [isLoading, setIsLoading] = useState(false)
  const [ctxNews, setCtxNews] = useState<NewsItem[]>([])
  const [ctxHomeProjects, setCtxHomeProjects] = useState<ProjectItem[]>([])
  const [ctxProjects, setCtxProjects] = useState<ProjectItem[]>([])
  const [ctxDirectors, setCtxDirectors] = useState<DirectorItem[]>([])
  const [ctxSettings, setCtxSettings] = useState<Settings>({} as Settings)

  const fetchNews = useCallback(() => {
    setIsLoading(true)

    httpFetch(ENDPOINTS.APIROOT + ENDPOINTS.NEWS, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(fetchedNews => {
        let formattedFetchedData = Array<NewsItem>()
        fetchedNews.entries.map((x: NewsItemCP) => {
          formattedFetchedData.push({
            title: x.Title,
            text: x.Text,
            photo: x.Photo,
            date: x.Date,
          } as NewsItem)
        })
        return formattedFetchedData
      })
      .then(formattedNews => {
        setCtxNews(formattedNews)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [setCtxNews])

  const fetchHomeProjects = useCallback(() => {
    setIsLoading(true)

    httpFetch(ENDPOINTS.APIROOT + ENDPOINTS.PROJECTS, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        filter: { ShowOnHome: true },
        sort: { _o: -1 },
      }),
    })
      .then(fetchedProjects => {
        let formattedFetchedData = Array<ProjectItem>()
        fetchedProjects.entries
          .sort((a: { _o: number }, b: { _0: number }) =>
            a._o > b._0 ? 1 : -1
          )
          .map((x: ProjectItem) => {
            formattedFetchedData.push({
              Name: x.Name,
              BackgroundMedia: x.BackgroundMedia,
            } as ProjectItem)
          })
        //return formattedFetchedData
        return fetchedProjects
      })
      .then(formattedProjects => {
        setCtxHomeProjects(formattedProjects.entries)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [setCtxHomeProjects])

  const fetchProjects = useCallback(() => {
    setIsLoading(true)

    httpFetch(ENDPOINTS.APIROOT + ENDPOINTS.PROJECTS, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        sort: { _o: -1 },
      }),
    })
      .then(fetchedProjects => {
        let formattedFetchedData = Array<ProjectItem>()
        fetchedProjects.entries
          .sort((a: { _o: number }, b: { _0: number }) =>
            a._o > b._0 ? 1 : -1
          )
          .map((x: ProjectItem) => {
            let formattedGallery = Array<GalleryItem>()
            x.Gallery.length > 0
              ? x.Gallery.map((g: GalleryItem) => {
                  formattedGallery.push({
                    path: ENDPOINTS.ROOT + g.path,
                  } as GalleryItem)
                })
              : null

            formattedFetchedData.push({
              Name: x.Name,
              Ref: x.Ref,
              Date: x.Date,
              Client: x.Client,
              Category: x.Category,
              Gallery: formattedGallery,
            } as ProjectItem)
          })
        return formattedFetchedData
      })
      .then(formattedProjects => {
        setCtxProjects(formattedProjects)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [setCtxProjects])

  const fetchDirectors = useCallback(() => {
    setIsLoading(true)
    httpFetch(ENDPOINTS.APIROOT + ENDPOINTS.DIRECTORS, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(fetchedDirectors => {
        let formattedFetchedData = Array<DirectorItem>()
        fetchedDirectors.entries.map((x: DirectorItem) => {
          formattedFetchedData.push({
            Name: x.Name,
            Text: x.Text,
            Function: x.Function,
          } as DirectorItem)
        })
        return formattedFetchedData
      })
      .then(formattedDirectors => {
        setCtxDirectors(formattedDirectors)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [setCtxDirectors])

  const fetchSettings = useCallback(() => {
    setIsLoading(true)
    httpFetch(ENDPOINTS.APIROOT + ENDPOINTS.SETTINGS, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(fetchedSettings => {
        let formattedFetchedData = {
          facebookUrl: fetchedSettings.FacebookUrl,
          instagramUrl: fetchedSettings.InstagramUrl,
          behanceUrl: fetchedSettings.BehanceUrl,
          directorsBackgroundPath:
            ENDPOINTS.ROOT + fetchedSettings.DirectorsBackground,
          aboutUsBackgroundPath:
            ENDPOINTS.ROOT + fetchedSettings.AboutUsBackground,
        } as Settings

        return formattedFetchedData
      })
      .then(formattedSettings => {
        setCtxSettings(formattedSettings)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [setCtxSettings])

  // -----------------------------------------------------------------------------------

  return useMemo(
    () => ({
      ctxNews,
      ctxHomeProjects,
      ctxProjects,
      ctxDirectors,
      ctxSettings,
      isLoading,
      fetchNews,
      fetchHomeProjects,
      fetchProjects,
      fetchDirectors,
      fetchSettings,
    }),
    [
      ctxNews,
      ctxHomeProjects,
      ctxProjects,
      ctxDirectors,
      ctxSettings,
      isLoading,
      fetchNews,
      fetchHomeProjects,
      fetchProjects,
      fetchDirectors,
      fetchSettings,
    ]
  )
}

export function useColorsDataLoading() {
  const {
    fetchNews,
    fetchHomeProjects,
    fetchProjects,
    fetchDirectors,
    fetchSettings,
  } = useContext(ColorsContext)
  useEffect(() => {
    fetchNews(),
      fetchHomeProjects(),
      fetchProjects(),
      fetchDirectors(),
      fetchSettings()
  }, [
    fetchNews,
    fetchHomeProjects,
    fetchProjects,
    fetchDirectors,
    fetchSettings,
  ])
}

import React from "react"
import { ColorsContext, useColorsContextValue } from "./src/data/colorsContext"

// IntersectionObserver polyfill for gatsby-image (Safari, IE)
export const onClientEntry = async () => {
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`)
  }
}

const ColorsApp = ({ appProps }) => {
  const colorsContextValue = useColorsContextValue()

  return (
    <ColorsContext.Provider value={colorsContextValue}>
      {appProps}
    </ColorsContext.Provider>
  )
}

export const wrapRootElement = ({ element }) => {
  return <ColorsApp appProps={element} />
}

import React from "react"
import { Helmet } from "react-helmet"
import { ENDPOINTS } from "../config"
import { NewsItemCP } from "../models/cockpitmodels"
import { NewsItem } from "../models/models"

interface Props {
  children: React.ReactNode
}

export const PreloadFont = ({ children }: Props) => (
  <Helmet>
    <link
      rel="preload"
      href={`/${children}.ttf`}
      as="font"
      crossOrigin="anonymous"
    />
  </Helmet>
)

export async function httpFetch(request: RequestInfo, init?: RequestInit) {
  const response = await fetch(request, init)
  const body = await response.json()
  return body
}
export async function httpFetchTyped<T>(
  request: RequestInfo,
  init?: RequestInit
): Promise<T> {
  const response = await fetch(request, init)
  const body = await response.json()
  return body
}

export async function getNews() {
  await httpFetch(ENDPOINTS.APIROOT + ENDPOINTS.NEWS, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
  }).then(fetchedNews => {
    let formattedFetchedData = Array<NewsItem>()
    fetchedNews.entries.map((x: NewsItemCP) => {
      formattedFetchedData.push({
        title: x.Title,
        text: x.Text,
        photo: x.Photo,
        date: x.Date,
      } as NewsItem)
    })
    return formattedFetchedData
  })
}

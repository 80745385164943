export const Version = {
  VERSION: "1.4.1",
}

export const Color = {
  LIGHT_GRAY: "#efefef",
  GRAY: "#818181",
  WHITE: "#fff",
  BLACK: "#000",
}

export const Font = {
  BODY: "Helvetica",
  HEADING: "Helvetica",
  NEWS: "Helvetica",
}

export const Path = {
  HOME: "/",
  ABOUTUS: "/aboutus",
  PROJECTS: "/projects",
  SERVICES: "/services",
  SHOP: "https://colorsxstudio.shop/",
  LEGALNOTES: "/legalnotes",
}

export const ENDPOINTS = {
  ROOT: "https://admin.colorsxstudio.com/",
  APIROOT: "https://admin.colorsxstudio.com/cockpit",
  NEWS: "/api/collections/get/News",
  PROJECTS: "/api/collections/get/projects",
  DIRECTORS: "/api/collections/get/Directors",
  SETTINGS: "/api/singletons/get/settings",
}
